import React, { Component } from 'react';
import { Form, Button, Message, Dimmer, Loader, Container } from 'semantic-ui-react';
import { Row, Column } from '../components/common/grid';
import InternalResult from '../components/searchResults/internalResult';
// @ts-ignore
import Helmet from 'react-helmet';
import { API_URL } from '../constants/constants';
import Axios from 'axios';
import { navigate, Link } from 'gatsby';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class PostJob extends Component {
  state = {
    errors: false,
    schoolName: '',
    email: '',
    jobTitle: '',
    city: 'Anywhere',
    country: 'Anyplace',
    jobDescription: '',
    jobPosterId: undefined,
    externalPosting: false,
    postingApproved: true,
    loading: true,
    link: undefined,
    dateAdded: new Date(),
    isMessageDismissed: false
  };

  async componentDidMount() {
    if (this.props.userId !== null && this.props.userId !== null && this.props.userId !== undefined && this.props.userId !== 'undefined') {
      this.setState({
        loading: false,
        jobPosterId: this.props.userId,
        isMessageDismissed: localStorage.getItem('isMessageDismissed')
      });
    } else {
      this.setState({ loading: false, externalPosting: true });
    }
  }

  handlePostJob = () => {
    this.setState({ loading: true, errors: false, errorMessage: '' });
    const { schoolName, email, jobTitle, city, country, jobDescription, externalPosting, dateAdded, postingApproved } = this.state;
    const jobPosterId = this.props.userId;

    // Hacky way to assign unique link so MongoDB doesn't remove these as duplicates
    const link = Math.random() * (1 - 100000000000) + 1;

    if (
      this.state.schoolName.trim().length > 1 &&
      this.state.email.trim().length > 1 &&
      this.state.jobTitle.trim().length > 1 &&
      this.state.city.trim().length > 1 &&
      this.state.country.trim().length > 1 &&
      this.state.jobDescription.trim().length > 1
    ) {
      const job = {
        name: schoolName,
        email,
        jobTitle,
        city,
        country,
        jobDescription,
        jobPosterId: this.props.userId,
        externalPosting,
        postingApproved,
        dateAdded,
        link
      };
      Axios.post(`${API_URL}/job`, job).then(response => {
        if (response.data.success) {
          if (localStorage.getItem('userId')) {
            this.setState({ loading: false });
            navigate('/account');
            toast.success('Job posted');
          } else {
            this.setState({ loading: false });
            navigate('/');
          }
        } else {
          this.setState({
            loading: false,
            errorMessage: response.data.message
          });
          toast.error('Error posting job: ' + error);
        }
      });
    } else {
      this.setState({
        errors: true,
        errorMessage: 'Something broke',
        loading: false
      });
    }
  };

  dismissMessage = () => {
    localStorage.setItem('isMessageDismissed', true);
    this.setState({ isMessageDismissed: true });
  };

  render() {
    return !this.state.loading ? (
      <>
        <Helmet
          title={`eslbot | post a job`}
          meta={[
            { name: 'description', content: `Post a job. You can post as many jobs as you want, for free.` },
            {
              name: 'keywords',
              content: `tefl tesol esl post job free`
            }
          ]}
        />
        <Container>
          <Form>
            <Row>
              <Column>
                <Form.Input
                  placeholder='School or Company Name'
                  type='text'
                  label='School or Company Name'
                  onChange={event =>
                    this.setState({
                      schoolName: event.target.value
                    })
                  }
                />
              </Column>
              <Column>
                <Form.Input
                  placeholder='Your Email Address'
                  type='text'
                  label='Your Email Address'
                  onChange={event =>
                    this.setState({
                      email: event.target.value
                    })
                  }
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Form.Input
                  placeholder='Job Title'
                  type='text'
                  label='Job Title'
                  onChange={event =>
                    this.setState({
                      jobTitle: event.target.value
                    })
                  }
                />
              </Column>
              <Column>
                <Form.Input
                  placeholder='City'
                  type='text'
                  label='City'
                  onChange={event => {
                    this.setState({
                      city: event.target.value
                    });
                    if (this.state.country === 'Anyplace') {
                      this.setState({ country: '' });
                    }
                  }}
                />
              </Column>
              <Column>
                <Form.Input
                  placeholder='Country'
                  type='text'
                  label='Country'
                  onChange={event => {
                    this.setState({
                      country: event.target.value
                    });
                  }}
                />
              </Column>
            </Row>
            <Row>
              <Column>
                <Form.TextArea
                  placeholder='Describe the job. Talk about stuff like salary, class size, and whatnot'
                  label='Job Description'
                  onChange={event =>
                    this.setState({
                      jobDescription: event.target.value
                    })
                  }
                />
              </Column>
            </Row>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {this.state.errors ? <Message negative>All Fields Are Required</Message> : null}
              <Button primary onClick={this.handlePostJob}>
                Post Job
              </Button>
            </div>
          </Form>
          {this.state.schoolName.length > 0 ? (
            <div>
              <h3>Search Result Preview</h3>

              <InternalResult
                job={{
                  _id: 'like anyone will ever see this',
                  link: undefined,
                  country: this.state.country,
                  city: this.state.city,
                  jobTitle: this.state.jobTitle,
                  name: this.state.schoolName,
                  updatedAt: new Date()
                }}
              />
            </div>
          ) : null}
          {!this.props.userId ? (
            <Message color='yellow'>
              <Message.Header>Register before you post</Message.Header>
              <Message.List>
                <Message.Item>
                  We highly recommend that you{' '}
                  <strong>
                    <Link to='register'>create an account</Link>
                  </strong>{' '}
                  or{' '}
                  <strong>
                    <Link to='login'>login</Link>
                  </strong>{' '}
                  before posting.
                </Message.Item>
                <Message.Item>
                  Registers users can <strong>edit and delete posts as well as bump posts to the top of the results</strong>.
                </Message.Item>
              </Message.List>
            </Message>
          ) : null}
          {!localStorage.getItem('isMessageDismissed') && !this.state.isMessageDismissed ? (
            <Message onDismiss={this.dismissMessage}>
              <Message.Header>How does this work?</Message.Header>
              <Message.List>
                <Message.Item>When you post a job, it's added to our seach results.</Message.Item>
                <Message.Item>
                  If a teacher likes your post and applies, you'll be emailed the teacher's info and a brief message.
                </Message.Item>
                <Message.Item>Posting jobs is free and unlimited.</Message.Item>
              </Message.List>
            </Message>
          ) : null}
        </Container>
        <Dimmer inverted active={this.state.loading}>
          <Loader content='Posting job' />
        </Dimmer>
      </>
    ) : (
      <Dimmer inverted active>
        <Loader content='Loading...' />
      </Dimmer>
    );
  }
}

const mapStateToProps = state => {
  return { userId: state.userId };
};

export default connect(mapStateToProps)(PostJob);
